import * as React from "react";
import { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import HTML5Video from "../global/video.js";


class Content extends Component {
  constructor() {
    super();
    this.state = {
      show: false,
      VideoSrc: undefined,
      VideoThumb: undefined,
    };
  }
  handleClose = () => {
    this.setState({ show: false });
  };
  handleShow = () => {
    this.setState({ show: true });
  };
  handleClick = (video) => {
    let VideoSrc;
    let VideoThumb;
    let VideoTrackLabel;
    switch (video) {
      case "Carmarion":
        VideoThumb = "/carmarions-story-thumbnail.jpg";
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917246499/rendition/720p/file.mp4?loc=external&signature=1353fbe4f0b62befe292c5647624f3e2f6f5adb2273e094367a8a527f17e1f2b";
        VideoTrackLabel = "Carmarion's Story";
        break;
      case "Douglas":
        VideoThumb = "/dougs-story-thumbnail.jpg";
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917246568/rendition/720p/file.mp4?loc=external&signature=2227b83dc86b560dd2d090016634d13f01b382388438b1f5e7ecec2a5d94b096";
        VideoTrackLabel = "Douglas's Story";
        break;
      case "Evan":
        VideoThumb = "/evans-story-thumbnail.jpg";
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917246596/rendition/720p/file.mp4?loc=external&signature=15a7f49a28a3fa7cff61986d96d9af8d53e9bd74989cb901be6e5a40b60c1c79";
        VideoTrackLabel = "Evan's Story";
        break;
      case "Jamell":
        VideoThumb = "/jamells-story-thumbnail.jpg";
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917246622/rendition/720p/file.mp4?loc=external&signature=bc0284b910fc6a8aa66f29b59e1b246114fe2e0c97c505045c32b212d43b39ca";
        VideoTrackLabel = "Jamell's Story";
        break;
      case "Sadiya":
        VideoThumb = "/sadiyas-story-thumbnail.jpg";
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917246648/rendition/720p/file.mp4?loc=external&signature=e9c2b7ec45dc7327e64410b66d9515b5741dbc76a3e9476f932753c9641ce610";
        VideoTrackLabel = "Sadiya's Story";
        break;
      case "Cleve":
        VideoThumb = "/cleves-story-thumbnail.jpg";
        VideoSrc =
          "https://player.vimeo.com/progressive_redirect/playback/917246538/rendition/1080p/file.mp4?loc=external&signature=231b2658a85757b058b7595f97a1b186c4a23919f6909b931abbb5f532df6189";
        VideoTrackLabel = "Cleve's Story";
        break;
    }
    this.setState({
      show: true,
      VideoThumb: VideoThumb,
      VideoSrc: VideoSrc,
      VideoTrackLabel: VideoTrackLabel,
    });
    //this.handleShow();
  };

  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Container fluid className="hero voices-of-strength" />
        <Container className="voices-of-strength">
          <Row>
            <Col xs={9} lg={10} className="m-auto px-0">
              <div className="hero-callout muted-yellow-dark">
                
                <h1 className="title ">
                  <span className="sub-title d-block">Voices of Strength</span>
                  Coming together to silence stigma
                </h1>
              </div>
              <p>
                Stigma divides us. It isolates us and stands in the way of
                people making progress. “Voices of Strength” is a video series
                that celebrates personal stories of strength, perseverance, and
                triumph in the face of stigma. Together, our voices can silence
                the stigma surrounding HIV.
              </p>
              <Container>
                <Row className="videos">
                  <HTML5Video
                    handleClose={this.handleClose}
                    show={this.state.show}
                    thumbnail={this.state.VideoThumb}
                    url={this.state.VideoSrc}
                    videoTrackLabel={this.state.VideoTrackLabel}
                  />
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("Carmarion")}
                      src={"/carmarions-story-thumbnail.jpg"}
                    />
                    <span className="h3">Carmarion's Story</span>
                    <p className="description">
                      Carmarion’s story is one of resilience in the face of
                      stigma—love triumphing over hate. See how she is working
                      as a force of change in the community.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("Douglas")}
                      src={"/dougs-story-thumbnail.jpg"}
                    />
                    <span className="h3">Douglas’s Story</span>
                    <p className="description">
                      Come along for Douglas’s journey with HIV: from his
                      diagnosis in the early days of the epidemic to confronting
                      stigma to finding acceptance, freedom, and happiness.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("Evan")}
                      src={"/evans-story-thumbnail.jpg"}
                    />
                    <span className="h3">Evan’s Story</span>
                    <p className="description">
                      As a transgender man in the military, Evan faced bias and
                      discrimination. He recounts his story about overcoming
                      stigma and finding pride in being himself.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("Jamell")}
                      src={"/jamells-story-thumbnail.jpg"}
                    />
                    <span className="h3">Jamell's Story</span>
                    <p className="description">
                      See how Jamell has moved past silence and stigma to find a
                      sense of liberation in living his truth and sharing his
                      story.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("Sadiya")}
                      src={"/sadiyas-story-thumbnail.jpg"}
                    />
                    <span className="h3">Sadiya's Story</span>
                    <p className="description">
                      Sadiya's experience with overcoming stigma surrounding her
                      identity has taken her from a place of fear and isolation
                      to finding both a community and self-acceptance.
                    </p>
                  </Col>
                  <Col xs={12} lg={6} className="video-card px-0">
                    <img
                      onClick={() => this.handleClick("Cleve")}
                      src={"/cleves-story-thumbnail.jpg"}
                    />
                    <span className="h3">Cleve's Story</span>
                    <p className="description">
                      Learn the inspiration behind the AIDS Memorial Quilt, and
                      how Cleve's advocacy has changed hearts and minds since
                      the early days of the HIV epidemic.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Content;
